import classNames from 'classnames/bind';
import React from 'react';

import styles from './Text.module.scss';

type IColor =
  | 'white'
  | 'gray100'
  | 'gray200'
  | 'gray300'
  | 'gray400'
  | 'gray500'
  | 'gray800'
  | 'gray900'
  | 'blue300';

type ITag =
  | 'div'
  | 'span'
  | 'b'
  | 'strong'
  | 'p'
  | 'label'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'li';

export interface IProps {
  tag: ITag;
  align?: 'left' | 'center' | 'right';
  color?: IColor | 'inherit';
  size?: 'inherit' | 10 | 12 | 13 | 14 | 15 | 16 | 18 | 20 | 24 | 30 | 34 | 40 | 44 | 48 | 56 | 64;
  weight?: 'regular' | 'medium' | 'bold';
  opacity?: 70 | 80 | 100;
  maxWidth?: number;
  display?: 'inline' | 'inline-block' | 'block';
  className?: string;
  transform?: 'none' | 'uppercase';
  isCentered?: boolean;
  children: React.ReactNode;
  isBalanced?: boolean;
  testId?: string;
}

const cx = classNames.bind(styles);

export const Text: React.FC<IProps> = ({
  tag: Tag,
  align = 'left',
  size = 'inherit',
  color = 'inherit',
  weight = Tag === 'strong' || Tag === 'b' ? 'bold' : 'regular',
  className,
  maxWidth,
  opacity = 100,
  display = 'inline-block',
  transform = 'none',
  isCentered,
  children,
  isBalanced = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(Tag),
  testId,
}) => (
  <Tag
    className={cx(
      'text',
      align,
      weight,
      `size-${size}`,
      `color-${color}`,
      `opacity-${opacity}`,
      `transform-${transform}`,
      display,
      className,
      { isCentered, isBalanced },
    )}
    color={color}
    data-testid={testId}
    style={{ maxWidth }}
  >
    {children}
  </Tag>
);
